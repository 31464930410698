import { Injectable } from '@angular/core';
import { TranslateDefaultParser, TranslateParser } from '@ngx-translate/core';

@Injectable()
export class MessageParser extends TranslateParser {
  constructor(private defaultParser: TranslateDefaultParser) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  interpolate(expr: string | Function, params?: any): string | undefined {
    if (typeof expr === 'function' && !params) params = { count: 1 };
    return this.defaultParser.interpolate(expr, params);
  }

  getValue(target: any, key: string) {
    return this.defaultParser.getValue(target, key);
  }
}

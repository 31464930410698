import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import * as Sentry from '@sentry/angular';
import posthog from 'posthog-js';
import { environment } from './environments/environment';

if (environment.posthog.enabled) {
  posthog.init(environment.posthog.key, {
    api_host: environment.posthog.host,
    opt_in_site_apps: true,
  });
}

if (environment.sentry.enabled) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracePropagationTargets: [environment.gqlEndpoint],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
